import { MasterPageClassNames } from '@wix/thunderbolt-components'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { Props, IPageWillMountHandler, IPropsStore, PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name } from './symbols'
import type { LandingPagePageConfig } from './types'

const LANDING_PAGE_CLASS_NAME = 'landingPage'

const landingPageFactory: (propsStore: IPropsStore, featureConfig: LandingPagePageConfig) => IPageWillMountHandler = (
	propsStore,
	featureConfig
) => {
	const updateClassNames = (classNames: MasterPageClassNames) => {
		propsStore.update({
			masterPage: { classNames },
		})
	}

	return {
		async pageWillMount() {
			const isLandingPage = featureConfig.isLandingPage
			const currentClassNames: MasterPageClassNames = propsStore.get('masterPage').classNames || {}

			if (isLandingPage && !currentClassNames[LANDING_PAGE_CLASS_NAME]) {
				const classNames = { ...currentClassNames, [LANDING_PAGE_CLASS_NAME]: LANDING_PAGE_CLASS_NAME }
				updateClassNames(classNames)
			}

			if (!isLandingPage && currentClassNames[LANDING_PAGE_CLASS_NAME]) {
				const classNames = { ...currentClassNames }
				delete classNames[LANDING_PAGE_CLASS_NAME]
				updateClassNames(classNames)
			}
		},
	}
}

export const LandingPage = withDependencies([Props, named(PageFeatureConfigSymbol, name)], landingPageFactory)
