import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
	ExperimentsSymbol,
	Experiments,
} from '@wix/thunderbolt-symbols'

const tabClasses = ['focus-ring-active', 'keyboard-tabbing-on']

const KEYBOARD_NAVIGATION_ACCESSIBILITY_KEYS = ['Tab', 'ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft']
const BASIC_ACCESSIBILITY_KEYS = ['Tab']

const accessibilityFactory = (
	window: BrowserWindow,
	experiments: Experiments
): IPageDidMountHandler & IPageDidUnmountHandler => {
	const isRepeaterKeyboardNavigationAvailable = experiments['specs.thunderbolt.repeater_keyboard_navigation']

	const FOCUS_RING_KEYS = isRepeaterKeyboardNavigationAvailable
		? KEYBOARD_NAVIGATION_ACCESSIBILITY_KEYS
		: BASIC_ACCESSIBILITY_KEYS

	const addFocusRingAndKeyboardTabbingOnClasses = (event: KeyboardEvent): void => {
		if (FOCUS_RING_KEYS.includes(event.key)) {
			// TODO: Think of a better way to get the site container in page features
			const target: HTMLElement = window!.document.getElementById('SITE_CONTAINER')!
			target.classList.add(...tabClasses)
		}
	}

	const removeKeyboardTabbingOnClass = (): void => {
		const target: HTMLElement = window!.document.getElementById('SITE_CONTAINER')!
		target.classList.remove('keyboard-tabbing-on')
	}
	return {
		pageDidMount() {
			window!.addEventListener('keydown', addFocusRingAndKeyboardTabbingOnClasses)
			window!.addEventListener('click', removeKeyboardTabbingOnClass)
		},
		pageDidUnmount() {
			window!.removeEventListener('keydown', addFocusRingAndKeyboardTabbingOnClasses)
			window!.removeEventListener('click', removeKeyboardTabbingOnClass)
		},
	}
}

export const Accessibility = withDependencies([BrowserWindowSymbol, ExperimentsSymbol], accessibilityFactory)
